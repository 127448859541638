.error_sec_main_div {
    padding-top: 80px;
}
.logo_div {
    text-align: center;
    margin-bottom: 40px;
}
.error_contact_div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 80px;
}
.button_div {
    background-color: #1a1a1a;
    color: #C9C4B7;
    min-width: 177px;
    border-radius: 40px;
    text-align: center;
    font-size: 16px;
    display: block;
    padding: 13px 10px;
    font-weight: 500;
    cursor: pointer;
}
.button_div:hover {
    background-color: #C9C4B7 !important;
    color: #000 !important;
}


@media only screen and (min-width: 320px) and (max-width: 767px) {
    .error_sec_main_div {
        padding-top: 60px;
     }
    .error_contact_div {
        flex-wrap: wrap;
        justify-content: center;
        margin-top: 50px;
    }
    .common_box {
        text-align: center;
        margin: 15px 0px;
    }
    .button_div {
        margin: 0px 10px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1199px) {
    .error_sec_main_div {
        padding-top: 60px;
     }
    .button_div {
        margin: 0px 10px;
    }
}